<template>
    <AppPanel :location="[title]" subtitle="Preencha os campos abaixo" :hasBackButton="true" backPath="/perguntas-sesmt">
        <template #content>
            <AppFormCadastro
                :service="service"
                :form="form"
                backPath="/perguntas-sesmt"
                @onBeforeSave="onBeforeSave"
                @onAfterSave="onAfterSave"
                @onLoadDataEdit="onLoadDataEdit"
                @onValidate="onValidate"
                @onError="onError"
            >
                <template #content>
                    <div class="card">
                        <div class="flex flex-row gap-2 align-items-center text-800 mb-1">
                            <AppInfoManual nomeTelaDoManual="perguntas-sesmt-form" />
                            <h3 class="ml-1">{{ title }}</h3>
                        </div>
                        <div class="grid">
                            <div class="field field-checkbox col-12">
                                <label for="ativo" class="mr-2 ml-0">Ativo</label>
                                <InputSwitch id="ativo" v-model="form.ativo" />
                                <label for="ativo" class="mr-2 ml-0 pl-4">Visível</label>
                                <InputSwitch id="visivel" v-model="form.visivel" />
                            </div>
                            <div class="field field-checkbox col-12" :style="{ display: 'none' }">
                                <label for="isSuits" class="mr-2 ml-0">isSuits</label>
                                <InputSwitch id="isSuits" v-model="form.isSuits" />
                            </div>
                            <div class="field col-6">
                                <label for="descricao">Pergunta</label>
                                <InputText
                                    v-model.trim="form.descricao"
                                    required="true"
                                    autofocus
                                    autocomplete="off"
                                    placeholder="Digite a pergunta"
                                    :class="{ 'p-invalid': submitted && !form.descricao }"
                                />
                                <small class="p-error" v-if="submitted && !form.descricao">Pergunta é obrigatória.</small>
                            </div>
                            <div class="field col-6">
                                <label for="mensagemAjuda">Mensagem do ícone de ajuda</label>
                                <InputText
                                    v-model.trim="form.mensagemAjuda"
                                    required="true"
                                    autofocus
                                    autocomplete="off"
                                    placeholder="Digite a mensagem para auxiliar na interpretação da pergunta"
                                />
                            </div>
                        </div>
                        <div class="grid">
                            <div class="field col-6">
                                <label for="tipo">Tipo de resposta</label>
                                <Dropdown
                                    id="tipo"
                                    class="selects"
                                    v-model="form.tipo"
                                    :options="tipos"
                                    @change="onChangeTipos"
                                    optionValue="value"
                                    optionLabel="label"
                                    placeholder="Selecione..."
                                    :class="{ 'p-invalid': submitted && !form.tipo }"
                                />
                                <small class="p-error" v-if="submitted && !form.tipo">Tipo é obrigatório.</small>
                            </div>
                            <div class="field col-6">
                                <label for="opcoes">Opções</label>
                                <Chips
                                    id="opcoes"
                                    class="chips"
                                    v-model="form.opcoes"
                                    :disabled="form.tipo !== 'ME' && form.tipo !== 'CB'"
                                    placeholder="Informe as opções"
                                    :class="{ 'p-invalid': submitted && !form.opcoes && (form.tipo === 'ME' || form.tipo === 'CB') }"
                                />
                                <small class="p-error" v-if="submitted && !form.opcoes && (form.tipo === 'ME' || form.tipo === 'CB')"
                                    >Opções é obrigatório.</small
                                >
                                <div v-if="form.tipo === 'ME' || form.tipo === 'CB'" class="field field-checkbox col-12">
                                    <InputSwitch id="outros" v-model="form.outros" />
                                    <label for="outros" class="ml-2">Outros</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </AppFormCadastro>
            <AppDescricaoDialog
                v-if="showMotivoDialogSuits"
                v-model:visible="showMotivoDialogSuits"
                v-model:title="messageModal"
                @onConfirm="onClickConfirmMotivoSuits"
                @onClose="onClickCloseMotivoSuits"
            >
            </AppDescricaoDialog>
        </template>
    </AppPanel>
</template>

<script>
import SesmtService from '../../services/SesmtService';
import EnumTipoPergunta from '../../enums/EnumTipoPergunta';
import AppInfoManual from '../../components/AppInfoManual.vue';

export default {
    data() {
        return {
            form: {
                ativo: true,
                visivel: true,
                isSuits: false
            },
            submitted: false,
            tipos: EnumTipoPergunta,
            messageModal: '',
            showMotivoDialogSuits: false
        };
    },
    created() {
        this.service = new SesmtService('/pergunta-sesmt');
    },
    computed: {
        title() {
            return this.$route.params.id ? 'Editar pergunta SESMT' : 'Adicionar pergunta SESMT';
        }
    },
    methods: {
        async onBeforeSave() {
            this.submitted = true;
        },
        async onAfterSave() {
            this.submitted = false;
        },
        onClickCloseMotivoSuits() {
            this.showMotivoDialogSuits = false;
        },
        async onError(err) {
            if (err?.response?.status === 403 && err?.response?.data?.details?.response?.return?.Suits?.includes('Essa é a última pergunta')) {
                this.messageModal = `${err?.response?.data?.message}. Deseja realmente desvincular essa perguntar de todos os questionários citados? Isso fará com que os questionários passem a ficar inativos. Digite SIM abaixo para confirmar.`;
                this.showMotivoDialogSuits = true;
            }
            if (
                err?.response?.status === 403 &&
                err?.response?.data?.details?.response?.return?.Suits?.includes('deseja reativar a pergunta no(s) questionário(s)')
            ) {
                this.messageModal = `${err?.response?.data?.message}. Essa ação fará com que a pergunta se torne ativa e fique vinculado ao questionário também ativo. Digite SIM abaixo para confirmar.`;
                this.showMotivoDialogSuits = true;
            }
        },
        async onClickConfirmMotivoSuits(descricao) {
            if (descricao.toLowerCase() === 'sim') {
                this.form = { ...this.form, isSuits: true };
                this.onClickCloseMotivoSuits();
                document.querySelector('div').getElementsByClassName('p-button p-component p-button')[3].click();
            }
        },
        async onValidate(cbSuccess, cbError) {
            if (this.form?.opcoes) {
                if (this.form.opcoes.filter((opcao, index) => this.form.opcoes.indexOf(opcao) != index).length >= 1) {
                    this.$toast.add({ severity: 'warn', summary: 'Existe opções duplicadas.', life: 3000 });
                    return cbError();
                }
            }
            if (this.hasError()) {
                return cbError();
            }
            return cbSuccess();
        },
        async onLoadDataEdit(data) {
            this.form = data;
        },
        hasError() {
            if (!this.form.descricao) return true;
            if ((this.form.tipo === 'ME' || this.form.tipo === 'CB') && !this.form.opcoes) return true;
            if (!this.form.tipo) return true;
        },
        onChangeTipos(e) {
            if (e.value !== 'ME' && e.value !== 'CB') {
                this.form.opcoes = null;
            }
        }
    },
    components: { AppInfoManual }
};
</script>

<style scoped lang="scss">
.multiSelect {
    padding: 0.25rem 0.5rem;
    border-radius: 6px;
}

.selects {
    border-radius: 6px;
}

.chips {
    height: 37px;
}
</style>
